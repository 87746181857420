import { useRef, useState } from "react"
import "./style/App.css"
import { Tab } from "@mui/material";


const sumFunc = function sum(a, b){
  let sum = a + b; 
  return sum;
}

export default function FuncBlock({func}){
    const aRef = useRef(0);
    const bRef = useRef(0);
    const [output, setOutput] = useState(0);


    return(
        <div className="func">
          <pre className="code_block">
            <code >
              <div>
              function sum(a, b)&#123;<br></br>
                <Tab></Tab>sum = a + b;<br></br> 
                <Tab></Tab>return sum;<br></br>
                &#125;<br></br>
              </div>
              
            </code>
          </pre>
          <div>
            <div className="code_block">
            sum(
              <input ref={aRef} type="number" className="param">
              </input>    ,   
              <input ref={bRef} type="number" className="param">
              </input>
            )
            <button onClick={() => {setOutput(sumFunc(parseInt(aRef.current.value), parseInt(bRef.current.value)))}} className="run_button">Run</button>
              <div className="output">
              <div style={{textAlign: "center"}}>
                Output<br></br>
                {output}
            </div>
              </div> 
            </div>
            
           
            
          </div>
        </div>

        
    )
}