import { Tab } from "@mui/material"
import { useRef, useState } from "react"
export default function ObjectEx(){
    const firstName = useRef("Robert");
    const lastName = useRef("Bourne");
    const age = useRef(21);
    const [output, setOutput] = useState();
    class Person {
        constructor(firstName, lastName, age) {
          this.firstName = firstName;
          this.lastName = lastName;
          this.age = age;
      }
      toString(){
        return `\nage: ${this.age}\nfirstName: "${this.firstName}"\nlastName: "${this.lastName}"`
      }
    }
    console.log(output)
    return (
        <>
        <div style={{"textAlign" : "center", "fontSize" : "32px", "fontWeight" : "500"}}>
            Objects are a collection of data and variables. They also have their own functions they can call to use their traits as inputs.<br></br>
            Below we can see a object being made! It has traits of first and last name along with age.
          </div>
          <pre>
              <div className="code_block" style={{"backgroundColor":"rgb(255, 255, 123, .7)"}}>
              class Person &#123;<br/>
              <Tab></Tab>constructor(firstName, lastName, age) &#123;<br/>
              <Tab></Tab>this.firstName = firstName;<br/>
              <Tab></Tab>this.lastName = lastName;<br/>
              <Tab></Tab>this.age = age;<br/>
              <Tab></Tab>&#125;<br/>
                      &#125;<br/>
                      <br/>
              let robert = new Person("Robert", "Bourne", 21)<br/>
              console.log(robert);<br/>
              <br/> 
            </div>
            <div className="code_block" style={{"backgroundColor":"rgb(255, 255, 123, .7)"}}>
            <div style={{textAlign: "center"}}>
                Output
            </div>
            age : 21<br/>
            firstName : "Robert"<br/>
            lastName: "Bourne<br/>
            </div>
            </pre>
            <div className="subheading">
                Try your own!
            </div>
            <pre className="code_block" style={{"backgroundColor":"rgb(255, 255, 123, .7)", "width": "100%"}}>
            <div className="subheading">
                console.log(new Person(
                    <input ref={firstName} type="text" className="param" placeholder="First name">
                    </input>, 
                    <input ref={lastName} type="text" className="param" placeholder="Last name">
                    </input>,
                    <input ref={age} type="number" className="param" placeholder="Age">
                    </input>
                ));
                <br></br>
                <button onClick={() => {setOutput(new Person(firstName.current.value, lastName.current.value, age.current.value))}} className="run_button">Run</button>
            </div>
            </pre>
            <pre>
            <div className="code_block" style={{"backgroundColor":"rgb(255, 255, 123, .7)"}}>
            <div style={{textAlign: "center"}}>
                Output
            </div>
                {output ? <div>
                             <div>age: {output.age}</div>
                             <div>firstName: {output.firstName}</div>
                             <div>lastName: {output.lastName}</div>
                            <div>You made an Object!</div>
                        </div> : ""}
                </div>
            </pre>
            
        </>
    )
}