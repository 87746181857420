import { Tab } from "@mui/material"
import { useRef, useState } from "react"

export default function VarBlock(){

    const bRef = useRef();
    const bRefTwo =useRef();
    const [outputB, setOutputB] = useState();
    const [outputY, setOutputY] = useState();
    return (
        <>
            <div className="subheading">
            Variables allow us to store values that we can call later.<br/>
            In JavaScript variables can change their type and values. This is not true for many other languages!
            </div>
            <pre>
              <div className="code_block" style={{"backgroundColor":"rgba(74, 159, 233, 0.8)"}}>
              var a = 1;<br/>
              console.log(a);   //Print to screen<br/>
              var x = 3;<br/>
              console.log(x);<br/>
              
            </div>
            <div className="code_block" style={{"backgroundColor":"rgba(74, 159, 233, 0.8)"}}>
            <div style={{textAlign: "center"}}>
                Output
            </div>
            a: 1<br/>
            x: 3<br/>
            </div>
            </pre>

            <div className="subheading">
                Variables will also take data from other variables. Try the demo below to see!
            </div>
            <pre>
              <div className="code_block" style={{"backgroundColor":"rgba(74, 159, 233, 0.8)"}}>
             var b = <input className="param" ref={bRef}></input>;<br/>

             var y = b; //y copies value of b<br/>
             console.log(y);<br/>
             b = <input className="param" ref={bRefTwo}></input>; //giving b new value!<br/>
              
             console.log(b);<br/>
              <button className="run_button" onClick={() =>{setOutputB(bRefTwo.current.value); setOutputY(bRef.current.value)}}>Run</button>
            </div>
            <div className="code_block" style={{"backgroundColor":"rgba(74, 159, 233, 0.8)"}}>
            <div style={{textAlign: "center"}}>
                Output
            </div>
                y: {outputY}<br/>
                b: {outputB} <br/>
            </div>
            </pre>
        </>
    )
}