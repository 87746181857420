import { useEffect, useRef, useState } from "react"

import "./style/Canvas.css"
import Sliders from "./Sliders";

const hex2rgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    
    return `R: ${r}, G: ${g}, B: ${b}`;
}


export default function Canvas(){
    const canvasRef = useRef();
    const [height, setHeight] = useState(10);
    const [width, setWidth] = useState(100);
    const [xPos, setXPos] = useState(0);
    const [yPos, setYPos] = useState(0);
    const [color, setColor] = useState("#000000");
    const [dim, setDim] = useState({height: 0, width: 0});

    useEffect(() => {
        if (canvasRef.current){
            setDim({height: canvasRef.current.height, width: canvasRef.current.width});
        }
    }, []);
    
    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.fillStyle = color;
        context.fillRect(xPos, yPos, width, height);
    });

    



    return (
        <div>
            <div className="function_container">
                
                <div className="object">
                    context
                </div>
                <div>
                    .
                </div>
                <div className="function">
                    fillRect
                </div>
                (
                <div className="parameters">
                    {xPos}, {yPos}, {width}, {height}
                </div>
                )
            </div>
            
        
        <div className="canvas_container">
            
            <div className="slider_container">
                
                <Sliders setHeight={setHeight} dim={dim} setWidth={setWidth} setXPos={setXPos} setYPos={setYPos}></Sliders>
                <div style={{"textAlign":"center"}}>
                    <div style={{"paddingRight":"10px"}}>
                    Pick a color
                    </div>
                    <input className="color_input" type="color" onChange={(e) => setColor(e.target.value)}>
                        
                    </input>
                    <div className="color_label">
                        Color: {hex2rgb(color)}
                    </div>
                </div>
            </div>
            <canvas ref={canvasRef}  className="canvas"></canvas>
        </div>
        <div className="function_container" style={{"marginBottom": "150px"}}>
                
                <div className="object">
                    context
                </div>
                <div>
                    .
                </div>
                <div className="function">
                    fillRect
                </div>
                (
                <div className="parameters">
                    right, down, width, height
                </div>
                )
            </div>
    </div>

    )
}