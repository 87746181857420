import { Tab } from "@mui/material";
import Canvas from "./Canvas";
import "./style/App.css";
import FuncBlock from "./Code";
import ObjectEx from "./ObjectEx";
import VarBlock from "./Var";

function App() {
    
  return (
    <div className="App">
      <h1 style={{ textAlign: "center", fontSize: 75 }}>Welcome Garnet</h1>

      <div className="subheading">
        Today we are going to learn and use JavaScript!<br/><br/>
        <br/>You use JavaScript everyday! It is used in almost every website, including this one!
        <br/>JavaScript is an Object Oriented Programming (OOP) language. This is
        just one of many different types of programming models. An object is
        used to organize data so programmers can more easily use and change said
        data.<br/>
        Today we are going to learn about 3 key ideas of programming in JavaScript:<br/>
         <div style={{"backgroundColor": "rgba(74, 159, 233, 0.8)", "width": "fit-content", "margin":"auto", "marginBottom":"10px"}}>
              1. Variables
            </div>
          <div style={{"backgroundColor": "rgba(243, 134, 243, 0.7)", "width": "fit-content", "margin":"auto", "marginBottom":"10px"}}>
               2. Functions
              </div>
         <div style={{"backgroundColor": "rgb(255, 255, 123, .7)", "width": "fit-content", "margin":"auto", "marginBottom":"10px"}}>
                3. Objects
              </div>
      </div>
      
      <h3>Variables</h3>
        <VarBlock></VarBlock>
        
      <h3>Functions</h3>
      <div className="function_block">
        
        <div className="func_subheading">
          Functions are sections of code that allow the computer to do a task without having to rewrite the code everytime.<br/><br/>
          A function can have inputs and can produce an output. <br/><br/>
          The function here takes in two numbers and outputs their sum.
          
        </div>
        <FuncBlock>
 
        </FuncBlock>
      </div>
      
      <h3>Objects</h3>
      <ObjectEx></ObjectEx>
      <div style={{"textAlign" : "center", "fontSize" : "32px", "fontWeight" : "500"}}> 
      Example of object using a function
      </div>
      
      <Canvas></Canvas>
    
    </div>
  );
}

export default App;
