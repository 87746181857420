import { Slider } from "@mui/material";

export default function Sliders({
  dim,
  setHeight,
  setWidth,
  setXPos,
  setYPos,
}) {
  return (
    <div>
      <div className="slider">
        <div className="slider_title">Height</div>
        <Slider
          aria-label="Restricted values"
          valueLabelDisplay="auto"
          defaultValue={10}
          max={dim.height}
          onChange={(e, s) => setHeight(s)}
        ></Slider>
      </div>

      <div className="slider">
        <div className="slider_title">Width</div>
        <Slider
          aria-label="Restricted values"
          valueLabelDisplay="auto"
          defaultValue={10}
          max={dim.width}
          onChange={(e, s) => setWidth(s)}
        ></Slider>
      </div>
      <div className="slider">
        <div className="slider_title">Right</div>
        <Slider
          aria-label="Restricted values"
          valueLabelDisplay="auto"
          defaultValue={10}
          max={dim.width}
          onChange={(e, s) => setXPos(s)}
        ></Slider>
      </div>
      <div className="slider">
        <div className="slider_title">Down</div>
        <Slider
          aria-label="Restricted values"
          valueLabelDisplay="auto"
          defaultValue={10}
          max={dim.height}
          onChange={(e, s) => setYPos(s)}
        ></Slider>
      </div>
    </div>
  );
}
